// The default/minimum height of the page header, as this is the default height of the mobile and desktop main navs.
export const DEFAULT_PAGE_HEADER_HEIGHT = 65;

export const PAGE_HEADER_Z_INDEX = 3000;

export const MOBILE_HEADER_BREAKPOINT = 1060;

export const DEFAULT_SUB_NAV_HEIGHT = 52;

export const MOBILE_MAIN_IDENTIFIER = {
  ACCOUNT_MENU: 'ACCOUNT_MENU',
  EXPLORE_MENU: 'EXPLORE_MENU',
  MAIN_MENU: 'MAIN_MENU',
} as const;

export const MOBILE_EXPLORE_IDENTIFIER = {
  DEGREES_MENU: 'DEGREES_MENU',
  CERTIFICATE_MENU: 'CERTIFICATE_MENU',
  CERTS_MENU: 'CERTS_MENU',
  DOMAIN_MENU: 'DOMAIN_MENU',
  PREMIUM_PROGRAMS_MENU: 'PREMIUM_PROGRAMS_MENU',
  ENTERPRISE_EXPLORE_SUBMENU: 'ENTERPRISE_EXPLORE_SUBMENU',
  CONSUMER_EXPLORE_SUBMENU: 'CONSUMER_EXPLORE_SUBMENU',
} as const;

export type MobileMainIdentifierKeys = keyof typeof MOBILE_MAIN_IDENTIFIER;
export type MobileMainIdentifierValues = (typeof MOBILE_MAIN_IDENTIFIER)[MobileMainIdentifierKeys];

export type MobileExploreIdentifierKeys = keyof typeof MOBILE_EXPLORE_IDENTIFIER;
export type MobileExploreIdentifierValues = (typeof MOBILE_EXPLORE_IDENTIFIER)[MobileExploreIdentifierKeys];
